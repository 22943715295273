@import '../resources/vars';
@import '../resources/mixins';

.container {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: var(--btn-align);
}

.container {
	border: var(--btn-border);
	border-radius: var(--btn-border-radius);
	margin: var(--btn-margin);
	min-height: var(--btn-height);
	width: var(--btn-width);

	&[disabled] {
		opacity: var(--btn-effect-disabled);
		pointer-events: none;
	}

	&.companion {
		height: var(--btn-companion-height);
		justify-content: center;
		margin: var(--btn-companion-margin);
		width: var(--btn-companion-width);
	}

	&.major {
		background-color: var(--btn-major-bg-color);
		border: var(--btn-major-border);
		box-shadow: var(--btn-major-box-shadow);

		.text {
			color: var(--btn-major-label-color);
			font-weight: var(--btn-major-label-font-weight);
		}

		.icon {
			background-color: var(--btn-major-icon-bg-color);
			border: var(--btn-major-icon-border);

			path {
				fill: var(--btn-major-icon-color);
			}
		}
	}

	&.minor {
		background-color: var(--btn-minor-bg-color);
		border: var(--btn-minor-border);
		box-shadow: var(--btn-minor-box-shadow);

		.text {
			color: var(--btn-minor-label-color);
			font-weight: var(--btn-minor-label-font-weight);
		}

		.icon {
			background-color: var(--btn-minor-icon-bg-color);
			border: var(--btn-minor-icon-border);

			path {
				fill: var(--btn-minor-icon-color);
			}
		}
	}

	&.negative {
		background-color: var(--btn-negative-bg-color);
		border: var(--btn-negative-border);
		box-shadow: var(--btn-negative-box-shadow);

		.text {
			color: var(--btn-negative-label-color);
			font-weight: var(--btn-negative-label-font-weight);
		}

		.icon {
			background-color: var(--btn-negative-icon-bg-color);
			border: var(--btn-negative-icon-border);

			path {
				fill: var(--btn-negative-icon-color);
			}
		}
	}

	&.destructive {
		background-color: var(--btn-destructive-bg-color);
		border: var(--btn-destructive-border);
		box-shadow: var(--btn-destructive-box-shadow);

		.text {
			color: var(--btn-destructive-label-color);
			font-weight: var(--btn-destructive-label-font-weight);
		}

		.icon {
			background-color: var(--btn-destructive-icon-bg-color);
			border: var(--btn-destructive-icon-border);

			path {
				fill: var(--btn-destructive-icon-color);
			}
		}
	}

	&.account {
		.icon {
			path {
				fill: #fff;
			}
		}
	}
}

.icon {
	align-items: center;
	display: var(--btn-icon-display);
	flex-shrink: 0;
	justify-content: center;
}

.icon {
	@include with-accounts(background-color);
	border: var(--btn-icon-border);
	border-radius: var(--btn-icon-border-radius);
	height: var(--btn-icon-height);
	margin: var(--btn-icon-margin);
	width: var(--btn-icon-width);

	svg {
		height: var(--btn-icon-size);
		width: var(--btn-icon-size);
	}
}

.text {
	font-family: var(--btn-label-face);
	font-size: var(--btn-label-font-size);
	font-weight: var(--btn-label-font-weight);
	letter-spacing: var(--btn-label-letter-spacing);
	max-width: var(--btn-label-max-width);
	padding: var(--btn-label-padding);
	text-align: var(--btn-label-text-align);
	text-transform: var(--btn-label-text-transform);
}
