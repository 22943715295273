@import '../resources/vars';
@import '../resources/mixins';

.container {
	&::before,
	&::after {
		content: '';
		display: block;
	}

	&::before {
		border-top: var(--checklist-section-keyline-top);

		@include respond-to(xss) {
			margin: var(--checklist-section-keyline-top-margin);
		}

		@at-root :global(.sidebar) & {
			margin: var(--checklist-section-keyline-top-margin);
		}
	}

	&::after {
		border-bottom: var(--checklist-section-keyline-bottom);

		@include respond-to(xss) {
			margin: var(--checklist-section-keyline-bottom-margin);
		}

		@at-root :global(.sidebar) & {
			margin: var(--checklist-section-keyline-bottom-margin);
		}
	}

	&:first-child::before {
		border-top: var(--checklist-section-keyline-top-first);
	}
}

.header {
	background-color: var(--checklist-section-bg-colour);
	display: flex;
	min-height: var(--checklist-section-min-height);
	padding-bottom: var(--checklist-section-spacer-bottom);
	padding-top: var(--checklist-section-spacer-top);

	@include respond-to(xss) {
		margin: var(--checklist-section-margin);
	}

	@at-root :global(.sidebar) & {
		margin: var(--checklist-section-margin);
	}
}

.icon {
	display: var(--checklist-section-icon-display);
	margin: var(--checklist-section-icon-margin);

	svg {
		height: var(--checklist-section-icon-size);
		width: var(--checklist-section-icon-size);

		path {
			fill: var(--checklist-section-icon-colour);
		}
	}
}

.label {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
}

.heading {
	color: var(--checklist-section-heading-colour);
	font-family: var(--checklist-section-heading-family);
	font-size: var(--checklist-section-heading-size);
	font-weight: var(--checklist-section-heading-weight);
	letter-spacing: var(--checklist-section-heading-letter-spacing);
	line-height: var(--checklist-section-heading-line-height);
	margin: 0 0 var(--checklist-section-spacer-middle) 0;
	text-align: var(--checklist-section-heading-text-align);
	text-transform: var(--checklist-section-heading-text-transform);
}

.subheading {
	color: var(--checklist-section-sub-heading-colour);
	font-family: var(--checklist-section-sub-heading-family);
	font-size: var(--checklist-section-sub-heading-size);
	font-weight: var(--checklist-section-sub-heading-weight);
	letter-spacing: var(--checklist-section-sub-heading-letter-spacing);
	line-height: var(--checklist-section-sub-heading-line-height);
	margin: 0;
	text-align: var(--checklist-section-sub-heading-text-align);
	text-transform: var(--checklist-section-sub-heading-text-transform);
}

.toggle {
	align-items: center;
	background-color: var(--checklist-toggle-open-bg-colour);
	border: var(--checklist-toggle-open-border);
	border-radius: var(--checklist-toggle-border-radius);
	display: flex;
	height: var(--checklist-toggle-height);
	justify-content: center;
	margin: var(--checklist-toggle-margin);
	width: var(--checklist-toggle-width);

	&.active {
		background-color: var(--checklist-toggle-close-bg-colour);
		border: var(--checklist-toggle-close-border);

		path {
			fill: var(--checklist-toggle-close-icon-colour);
		}
	}

	svg {
		height: var(--checklist-toggle-icon-size);
		width: var(--checklist-toggle-icon-size);

		path {
			fill: var(--checklist-toggle-open-icon-colour);
		}
	}
}
