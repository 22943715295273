@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--knowledge-item-bg-colour);
	position: relative;

	&::after {
		border-bottom: var(--knowledge-item-keyline);
		content: '';
		display: block;
		max-width: var(--knowledge-item-keyline-max-width);

		@include respond-to(xss) {
			margin: var(--knowledge-item-keyline-margin);
		}

		@at-root :global(.sidebar) & {
			margin: var(--knowledge-item-keyline-margin);
		}
	}

	&:last-child::after {
		border-bottom: var(--knowledge-item-keyline-last);
	}
}

// Alignment
.button {
	align-items: center;
	display: flex;
}

// Box
.button {
	background-color: var(--knowledge-item-btn-bg-colour);
	box-shadow: var(--knowledge-item-btn-box-shadow);
	max-width: var(--knowledge-item-btn-max-width);
	min-height: var(--knowledge-item-btn-height);
	padding: var(--knowledge-item-btn-padding);

	@include respond-to(xss) {
		margin: var(--knowledge-item-btn-margin);
	}

	@at-root :global(.sidebar) & {
		margin: var(--knowledge-item-btn-margin);
	}
}

// Border
.button {
	border: var(--knowledge-item-btn-border);
	border-radius: var(--knowledge-item-btn-border-radius);

	@at-root .container:first-child & {
		border-top-left-radius: var(--knowledge-item-btn-border-radius-first);
		border-top-right-radius: var(--knowledge-item-btn-border-radius-first);
	}

	@at-root .container:last-child & {
		border-bottom-left-radius: var(--knowledge-item-btn-border-radius-last);
		border-bottom-right-radius: var(--knowledge-item-btn-border-radius-last);
	}
}

// Keyline
.button {
	&::after {
		border-left: var(--knowledge-item-indicator-width) solid var(--knowledge-item-indicator-off-colour);
		display: block;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;

		@include respond-to(lg) {
			content: '';
		}
	}

	&:hover::after {
		border-color: var(--knowledge-item-indicator-hover-colour);
	}

	&.selected::after {
		border-color: var(--knowledge-item-indicator-on-colour);
	}
}

.label {
	flex: 1 0;
	padding: var(--knowledge-item-label-padding);
}

.label {
	color: var(--knowledge-item-label-colour);
	font-family: var(--knowledge-item-label-font-family);
	font-size: var(--knowledge-item-label-size);
	font-weight: var(--knowledge-item-label-weight);
	letter-spacing: var(--knowledge-item-label-letter-spacing);
	line-height: var(--knowledge-item-label-line-height);
	text-align: var(--knowledge-item-label-align);
	text-transform: var(--knowledge-item-label-text-transform);
}

.action {
	align-items: center;
	display: flex;
	justify-content: center;
}

.action {
	background-color: var(--knowledge-item-action-bg-colour);
	border: var(--knowledge-item-action-bg-border);
	border-radius: var(--knowledge-item-action-bg-border-radius);
	height: var(--knowledge-item-action-bg-width);
	margin: var(--knowledge-item-action-bg-margin);
	width: var(--knowledge-item-action-bg-height);
}

.action {
	svg {
		height: var(--knowledge-item-action-icon-size);
		width: var(--knowledge-item-action-icon-size);
	}

	path {
		fill: var(--knowledge-item-action-icon-color);
	}
}
