@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--input-explainer-background-color);
	margin: var(--input-explainer-margin);
	padding: var(--input-explainer-padding);
}

.text {
	@include with-styled-text(input-explainer-label);
}
