@import '../resources/vars';
@import '../resources/mixins';

.container {
	border-bottom: var(--theme-nav-top-keyline);
	display: flex;
	height: var(--app-top-nav-height);
}

.navigation {
	border-right: var(--theme-nav-top-keyline);
	width: var(--app-nav-width);
}

.body {
	display: flex;
	flex: 1;
	position: relative;

	&.offset {
		padding-right: var(--app-sidebar-width);
	}
}

.icons {
	align-items: center;
	display: flex;
	height: 100%;
	position: absolute;

	&.left {
		left: 16px;
	}

	&.right {
		right: 16px;
	}
}

.slot {
	align-items: center;
	display: flex;
}

.slot,
.icon {
	&:not(:last-child) {
		margin-right: 8px;
	}
}

.icon {
	&.disabled {
		opacity: var(--pattern-disabled-opacity);
	}

	svg {
		height: var(--nav-top-icon-size);
		width: var(--nav-top-icon-size);
	}

	path {
		fill: var(--theme-nav-top-icon-off-colour);
	}
}

.logo {
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
}

.link {
	height: var(--nav-top-logo-height);
	width: var(--nav-top-logo-width);
}
