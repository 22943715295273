@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--swatch-general-dimmer);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: var(--app-z-index-layer-1000);
}

.soft {
	pointer-events: none;
	visibility: hidden;

	&[data-visible='true'] {
		pointer-events: all;
		visibility: visible;
	}
}
