@use '../resources.scss' as mixins;

:root {
	/* APP RELATED VARS: DO NOT MODIFY! */
	// SAFE AREA
	--sat: env(safe-area-inset-top, 0);
	--sar: env(safe-area-inset-right, 0);
	--sab: env(safe-area-inset-bottom, 0);
	--sal: env(safe-area-inset-left, 0);

	// Z-INDEX
	--app-z-index-layer-1: 1;
	--app-z-index-layer-10: 10;
	--app-z-index-layer-100: 100;
	--app-z-index-layer-1000: 1000;
	--app-z-index-layer-1500: 1500;
	--app-z-index-layer-9999: 9999;

	// PAGE
	--app-top-nav-height: 64px;
	--app-bottom-nav-height: 48px;
	--app-nav-width: 360px;
	--app-sidebar-width: var(--app-nav-width);

	// GRID
	--app-grid-max-width-xs: 536px;
	--app-grid-max-width-sm: 720px;

	--app-custom-grid-gap-column: 16px;

	@include mixins.respond-to(xs) {
		--app-custom-grid-width: var(--app-grid-max-width-xs);
	}

	@include mixins.respond-to(sm, up) {
		--app-custom-grid-width: var(--app-grid-max-width-sm);
	}
}

html,
body,
#root {
	display: flex;
	flex-direction: column;
}

html {
	// propagate box-sizing
	box-sizing: border-box;
	// fallback for old browser
	min-height: 100%;
	// ensured 100vh value
	min-height: calc(var(--vh, 1vh) * 100);
}

body,
#root {
	flex: 1;
}

body {
	&.frozen {
		overflow: hidden;
	}
}

body {
	background-attachment: var(--theme-bg-attachment);
	background-color: var(--theme-bg-colour);
	background-image: var(--theme-bg-image-url);
	background-position: var(--theme-bg-image-position);
	background-repeat: var(--theme-bg-image-repeat);
	background-size: var(--theme-bg-image-size);
	color: var(--typo-p-medium-colour);
}

body {
	font-family: var(--font-family), 'Barlow', sans-serif;
	font-size: var(--typo-p-medium-font-size);
	font-style: var(--typo-p-medium-font-style);
	font-weight: var(--typo-p-medium-font-weight);
	letter-spacing: var(--typo-p-medium-letter-spacing);
	line-height: var(--typo-p-medium-line-height);
	text-align: var(--typo-p-medium-text-align);
	text-transform: var(--typo-p-medium-text-transform);
}

#root {
	justify-content: space-between;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

* {
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

hr {
	background-color: var(--swatch-card-keylines);
	border-width: 0;
	color: var(--swatch-card-keylines);
	height: 1px;
	margin-block-end: 0;
	margin-block-start: 0;

	&.content-hr {
		background-color: var(--swatch-content-keylines);
		color: var(--swatch-content-keylines);
	}
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

figure {
	margin: 0;
}

a {
	text-decoration: none;

	&:visited,
	&:hover,
	&:active {
		color: inherit;
	}
}

button {
	@extend %button-reset;

	outline: none;

	@at-root html.user-is-tabbing &:focus {
		outline: auto 2px Highlight; // for non-webkit browsers
		outline: auto 5px -webkit-focus-ring-color; // for webkit browsers
	}
}

.grid,
.sub-grid {
	margin: 0 auto;
}

.grid {
	max-width: var(--app-custom-grid-width);

	&:not(.no-gutter) {
		@include mixins.respond-to(xss) {
			padding: 0 var(--app-custom-grid-gap-column);
		}
	
		@at-root .sidebar & {
			padding: 0 var(--app-custom-grid-gap-column);
		}
	}
}

.sub-grid {
	max-width: var(--layout-sub-width);
}

.typography {
	a,
	[data-type='link'] {
		background-color: var(--typo-a-link-bg-colour);
		border-bottom: var(--typo-a-link-border-bottom);
		border-radius: var(--typo-a-link-border-radius);
		color: var(--typo-a-link-colour);
		font-style: var(--typo-a-link-font-style);
		font-weight: var(--typo-a-link-font-weight);
		letter-spacing: var(--typo-a-link-letter-spacing);
		padding-bottom: var(--typo-a-link-padding-bottom);
		position: relative;
		text-decoration: var(--typo-a-link-text-decoration);
		text-transform: var(--typo-a-link-text-transform);
		top: var(--typo-a-link-position-top);

		&:visited {
			background-color: var(--typo-a-visited-bg-colour);
			border-bottom: var(--typo-a-visited-border-bottom);
			border-radius: var(--typo-a-visited-border-radius);
			color: var(--typo-a-visited-colour);
			font-style: var(--typo-a-visited-font-style);
			font-weight: var(--typo-a-visited-font-weight);
			letter-spacing: var(--typo-a-visited-letter-spacing);
			padding-bottom: var(--typo-a-visited-padding-bottom);
			text-decoration: var(--typo-a-visited-text-decoration);
			text-transform: var(--typo-a-visited-text-transform);
			top: var(--typo-a-visited-position-top);
		}

		&:hover {
			background-color: var(--typo-a-hover-bg-colour);
			border-bottom: var(--typo-a-hover-border-bottom);
			border-radius: var(--typo-a-hover-border-radius);
			color: var(--typo-a-hover-colour);
			font-style: var(--typo-a-hover-font-style);
			font-weight: var(--typo-a-hover-font-weight);
			letter-spacing: var(--typo-a-hover-letter-spacing);
			padding-bottom: var(--typo-a-hover-padding-bottom);
			text-decoration: var(--typo-a-hover-text-decoration);
			text-transform: var(--typo-a-hover-text-transform);
			top: var(--typo-a-hover-position-top);
		}

		&:active {
			background-color: var(--typo-a-active-bg-colour);
			border-bottom: var(--typo-a-active-border-bottom);
			border-radius: var(--typo-a-active-border-radius);
			color: var(--typo-a-active-colour);
			font-style: var(--typo-a-active-font-style);
			font-weight: var(--typo-a-active-font-weight);
			letter-spacing: var(--typo-a-active-letter-spacing);
			padding-bottom: var(--typo-a-active-padding-bottom);
			text-decoration: var(--typo-a-active-text-decoration);
			text-transform: var(--typo-a-active-text-transform);
			top: var(--typo-a-active-position-top);
		}
	}

	ul {
		color: var(--typo-ul-colour);
		font-family: var(--typo-ul-font-face);
		font-size: var(--typo-ul-font-size);
		font-style: var(--typo-ul-font-style);
		font-weight: var(--typo-ul-font-weight);
		letter-spacing: var(--typo-ul-letter-spacing);
		line-height: var(--typo-ul-line-height);
		list-style-image: var(--typo-ul-list-style-image);
		list-style-position: var(--typo-ul-style-position);
		list-style-type: var(--typo-ul-style-type);
		margin: var(--typo-ul-margin);
		padding: var(--typo-ul-padding);
		text-align: var(--typo-ul-text-align);
		text-transform: var(--typo-ul-text-transform);

		> li {
			margin: var(--typo-ul-list-item-margin);
			padding: var(--typo-ul-list-item-padding);
		}
	}

	ol {
		color: var(--typo-ol-colour);
		font-family: var(--typo-ol-font-face);
		font-size: var(--typo-ol-font-size);
		font-style: var(--typo-ol-font-style);
		font-weight: var(--typo-ol-font-weight);
		letter-spacing: var(--typo-ol-letter-spacing);
		line-height: var(--typo-ol-line-height);
		list-style-image: var(--typo-ol-list-style-image);
		list-style-position: var(--typo-ol-style-position);
		list-style-type: var(--typo-ol-style-type);
		margin: var(--typo-ol-margin);
		padding: var(--typo-ol-padding);
		text-align: var(--typo-ol-text-align);
		text-transform: var(--typo-ol-text-transform);

		> li {
			margin: var(--typo-ol-list-item-margin);
			padding: var(--typo-ol-list-item-padding);
		}
	}

	em {
		font-style: var(--typo-em-font-style);
	}

	strong {
		font-weight: var(--typo-strong-font-weight);
	}

	figure {
		a {
			border-bottom: 0;

			&:hover {
				border-bottom: 0;
			}
		}
	}

	figcaption {
		color: var(--typo-figcaption-colour);
		font-family: var(--typo-figcaption-font-face);
		font-size: var(--typo-figcaption-font-size);
		font-style: var(--typo-figcaption-font-style);
		font-weight: var(--typo-figcaption-font-weight);
		letter-spacing: var(--typo-figcaption-letter-spacing);
		line-height: var(--typo-figcaption-line-height);
		margin-bottom: var(--typo-figcaption-margin-bottom);
		text-transform: var(--typo-figcaption-text-transform);
	}

	blockquote {
		background-color: var(--typo-quote-bg-colour);
		border-bottom: var(--typo-quote-border-bottom);
		border-top: var(--typo-quote-border-top);
		color: var(--typo-quote-colour);
		font-family: var(--typo-quote-font-face);
		font-size: var(--typo-quote-font-size);
		font-style: var(--typo-quote-font-style);
		font-weight: var(--typo-quote-font-weight);
		letter-spacing: var(--typo-quote-letter-spacing);
		line-height: var(--typo-quote-line-height);
		margin: var(--typo-quote-margin);
		max-width: var(--typo-quote-max-width);
		padding: var(--typo-quote-padding);
		text-align: var(--typo-quote-text-align);
		text-transform: var(--typo-quote-text-transform);

		p {
			color: unset;
			font-family: unset;
			font-size: unset;
			font-style: unset;
			font-weight: unset;
			letter-spacing: unset;
			line-height: unset;
			text-align: unset;
			text-transform: unset;
		}

		p::before {
			content: '\201C';
		}

		p::after {
			content: '\201D';
		}

		cite {
			color: var(--type-quote-cite-colour);
			font-family: var(--type-quote-cite-font-face);
			font-size: var(--type-quote-cite-font-size);
			font-style: var(--type-quote-cite-font-style);
			font-weight: var(--type-quote-cite-font-weight);
			letter-spacing: var(--type-quote-cite-letter-spacing);
			line-height: var(--type-quote-cite-line-height);
			text-align: var(--type-quote-cite-text-align);
			text-transform: var(--type-quote-cite-text-transform);
		}
	}
}

.typography-extended {
	h1 {
		color: var(--typo-h1-colour);
		font-family: var(--typo-h1-font-face);
		font-size: var(--typo-h1-font-size);
		font-style: var(--typo-h1-font-style);
		font-weight: var(--typo-h1-font-weight);
		letter-spacing: var(--typo-h1-letter-spacing);
		line-height: var(--typo-h1-line-height);
		margin: var(--typo-h1-margin);
		text-align: var(--typo-h1-text-align);
		text-transform: var(--typo-h1-text-transform);
	}

	h2 {
		color: var(--typo-h2-colour);
		font-family: var(--typo-h2-font-face);
		font-size: var(--typo-h2-font-size);
		font-style: var(--typo-h2-font-style);
		font-weight: var(--typo-h2-font-weight);
		letter-spacing: var(--typo-h2-letter-spacing);
		line-height: var(--typo-h2-line-height);
		margin: var(--typo-h2-margin);
		text-align: var(--typo-h2-text-align);
		text-transform: var(--typo-h2-text-transform);
	}

	h3 {
		color: var(--typo-h3-colour);
		font-family: var(--typo-h3-font-face);
		font-size: var(--typo-h3-font-size);
		font-style: var(--typo-h3-font-style);
		font-weight: var(--typo-h3-font-weight);
		letter-spacing: var(--typo-h3-letter-spacing);
		line-height: var(--typo-h3-line-height);
		margin: var(--typo-h3-margin);
		text-align: var(--typo-h3-text-align);
		text-transform: var(--typo-h3-text-transform);
	}

	h4 {
		color: var(--typo-h4-colour);
		font-family: var(--typo-h4-font-face);
		font-size: var(--typo-h4-font-size);
		font-style: var(--typo-h4-font-style);
		font-weight: var(--typo-h4-font-weight);
		letter-spacing: var(--typo-h4-letter-spacing);
		line-height: var(--typo-h4-line-height);
		margin: var(--typo-h4-margin);
		text-align: var(--typo-h4-text-align);
		text-transform: var(--typo-h4-text-transform);
	}

	h5 {
		color: var(--typo-h5-colour);
		font-family: var(--typo-h5-font-face);
		font-size: var(--typo-h5-font-size);
		font-style: var(--typo-h5-font-style);
		font-weight: var(--typo-h5-font-weight);
		letter-spacing: var(--typo-h5-letter-spacing);
		line-height: var(--typo-h5-line-height);
		margin: var(--typo-h5-margin);
		text-align: var(--typo-h5-text-align);
		text-transform: var(--typo-h5-text-transform);
	}

	h6 {
		color: var(--typo-h6-colour);
		font-family: var(--typo-h6-font-face);
		font-size: var(--typo-h6-font-size);
		font-style: var(--typo-h6-font-style);
		font-weight: var(--typo-h6-font-weight);
		letter-spacing: var(--typo-h6-letter-spacing);
		line-height: var(--typo-h6-line-height);
		margin: var(--typo-h6-margin);
		text-align: var(--typo-h6-text-align);
		text-transform: var(--typo-h6-text-transform);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&.small {
			color: var(--typo-h-small-colour);
			font-family: var(--typo-h-small-font-face);
			font-size: var(--typo-h-small-font-size);
			font-style: var(--typo-h-small-font-style);
			font-weight: var(--typo-h-small-font-weight);
			letter-spacing: var(--typo-h-small-letter-spacing);
			line-height: var(--typo-h-small-line-height);
			margin: var(--typo-h-small-margin);
			text-align: var(--typo-h-small-text-align);
			text-transform: var(--typo-h-small-text-transform);
		}

		&.medium {
			color: var(--typo-h-medium-colour);
			font-family: var(--typo-h-medium-font-face);
			font-size: var(--typo-h-medium-font-size);
			font-style: var(--typo-h-medium-font-style);
			font-weight: var(--typo-h-medium-font-weight);
			letter-spacing: var(--typo-h-medium-letter-spacing);
			line-height: var(--typo-h-medium-line-height);
			margin: var(--typo-h-medium-margin);
			text-align: var(--typo-h-medium-text-align);
			text-transform: var(--typo-h-medium-text-transform);
		}

		&.large {
			color: var(--typo-h-large-colour);
			font-family: var(--typo-h-large-font-face);
			font-size: var(--typo-h-large-font-size);
			font-style: var(--typo-h-large-font-style);
			font-weight: var(--typo-h-large-font-weight);
			letter-spacing: var(--typo-h-large-letter-spacing);
			line-height: var(--typo-h-large-line-height);
			margin: var(--typo-h-large-margin);
			text-align: var(--typo-h-large-text-align);
			text-transform: var(--typo-h-large-text-transform);
		}
	}

	p {
		color: var(--typo-p-medium-colour);
		font-family: var(--typo-p-medium-font-face);
		font-size: var(--typo-p-medium-font-size);
		font-style: var(--typo-p-medium-font-style);
		font-weight: var(--typo-p-medium-font-weight);
		letter-spacing: var(--typo-p-medium-letter-spacing);
		line-height: var(--typo-p-medium-line-height);
		margin: var(--typo-p-medium-margin);
		text-align: var(--typo-p-medium-text-align);
		text-transform: var(--typo-p-medium-text-transform);

		&.small {
			color: var(--typo-p-small-colour);
			font-family: var(--typo-p-small-font-face);
			font-size: var(--typo-p-small-font-size);
			font-style: var(--typo-p-small-font-style);
			font-weight: var(--typo-p-small-font-weight);
			letter-spacing: var(--typo-p-small-letter-spacing);
			line-height: var(--typo-p-small-line-height);
			margin: var(--typo-p-small-margin);
			text-align: var(--typo-p-small-text-align);
			text-transform: var(--typo-p-small-text-transform);
		}

		&.large {
			color: var(--typo-p-large-colour);
			font-family: var(--typo-p-large-font-face);
			font-size: var(--typo-p-large-font-size);
			font-style: var(--typo-p-large-font-style);
			font-weight: var(--typo-p-large-font-weight);
			letter-spacing: var(--typo-p-large-letter-spacing);
			line-height: var(--typo-p-large-line-height);
			margin: var(--typo-p-large-margin);
			text-align: var(--typo-p-large-text-align);
			text-transform: var(--typo-p-large-text-transform);
		}

		&.concluding {
			color: var(--typo-p-concluding-colour);
			font-family: var(--typo-p-concluding-font-face);
			font-size: var(--typo-p-concluding-font-size);
			font-style: var(--typo-p-concluding-font-style);
			font-weight: var(--typo-p-concluding-font-weight);
			letter-spacing: var(--typo-p-concluding-letter-spacing);
			line-height: var(--typo-p-concluding-line-height);
			margin: var(--typo-p-concluding-margin);
			text-align: var(--typo-p-concluding-text-align);
			text-transform: var(--typo-p-concluding-text-transform);
		}

		&.cta {
			color: var(--typo-p-cta-colour);
			font-family: var(--typo-p-cta-font-face);
			font-size: var(--typo-p-cta-font-size);
			font-style: var(--typo-p-cta-font-style);
			font-weight: var(--typo-p-cta-font-weight);
			letter-spacing: var(--typo-p-cta-letter-spacing);
			line-height: var(--typo-p-cta-line-height);
			margin: var(--typo-p-cta-margin);
			text-align: var(--typo-p-cta-text-align);
			text-transform: var(--typo-p-cta-text-transform);
		}
	}
}
