@import '../resources/vars';
@import '../resources/mixins';

.section {
	height: var(--pattern-layout-spacer-sections-s);

	@include respond-to(sm, up) {
		height: var(--pattern-layout-spacer-sections-ml);
	}
}

.component {
	height: var(--pattern-layout-spacer-components-s);

	@include respond-to(sm, up) {
		height: var(--pattern-layout-spacer-components-ml);
	}
}

.content {
	height: var(--pattern-layout-spacer-content-s);

	@include respond-to(sm, up) {
		height: var(--pattern-layout-spacer-content-ml);
	}
}

.top {
	height: var(--pattern-layout-spacer-top-s);

	@include respond-to(sm, up) {
		height: var(--pattern-layout-spacer-top-ml);
	}
}

.bottom {
	height: var(--pattern-layout-spacer-bottom-s);

	@include respond-to(sm, up) {
		height: var(--pattern-layout-spacer-bottom-ml);
	}
}

.keyline-top {
	border-top: 1px solid var(--swatch-content-keylines);
}

.keyline-bottom {
	border-bottom: 1px solid var(--swatch-content-keylines);
}

.background {
	background-color: var(--pattern-layout-spacer-bg-colour);
}
