@import '../resources/vars';
@import '../resources/mixins';

.container {
	@extend %scroll-bar-reset;
}

.vertical {
	overflow-y: auto;
}

.horizontal {
	overflow-x: auto;
}
