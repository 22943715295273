@import '../resources/vars';
@import '../resources/mixins';

.container {
	--app-input-container-direction: var(--input-switch-container-direction);
	--app-input-container-align-items: var(--input-switch-container-align-items);
	--app-input-container-justify-content: var(--input-switch-container-justify-content);
	--app-input-container-padding: var(--input-switch-container-padding);
	--app-input-container-border-width: var(--input-switch-container-border-width);
	--app-input-container-border-style: var(--input-switch-container-border-style);
	--app-input-container-border-color: var(--input-switch-container-border-color);

	--app-input-label-direction: var(--input-switch-label-direction);
	--app-input-label-justify-content: var(--input-switch-label-justify-content);
	--app-input-label-align-items: var(--input-switch-label-align-items);
	--app-input-label-margin: var(--input-switch-label-margin);

	--app-input-informer-margin: var(--input-switch-informer-margin);
}

.wrapper,
.body {
	align-items: center;
	display: flex;
}

.body {
	cursor: pointer;
	user-select: none;
}

.body {
	@include outline-control('.input:focus + .body');
	background-color: var(--input-switch-off-body-bg-colour);
	border: var(--input-switch-off-body-border);
	border-radius: var(--input-switch-body-border-radius);
	box-shadow: var(--input-switch-off-body-box-shadow);
	box-sizing: content-box;
	height: var(--input-switch-body-height);
	padding: var(--input-switch-body-padding);
	width: var(--input-switch-body-width);

	&::after {
		background-color: var(--input-switch-off-handle-bg-colour);
		border: var(--input-switch-off-handle-border);
		border-radius: inherit;
		box-shadow: var(--input-switch-off-handle-box-shadow);
		box-sizing: border-box;
		content: '';
		display: block;
		height: var(--input-switch-handle-size);
		overflow: hidden;

		transition: transform 300ms linear;
		width: var(--input-switch-handle-size);
	}

	@at-root .input:checked {
		+ .body {
			background-color: var(--input-switch-on-body-bg-colour);
			border: var(--input-switch-on-body-border);
			box-shadow: var(--input-switch-on-body-box-shadow);

			&::after {
				background-color: var(--input-switch-on-handle-bg-colour);
				border: var(--input-switch-on-handle-border);
				box-shadow: var(--input-switch-on-handle-box-shadow);

				transform: translateX(100%);
			}
		}
	}

	@at-root .input:disabled {
		+ .body {
			opacity: var(--pattern-disabled-opacity);
		}
	}
}

.label {
	@include with-styled-text(input-switch-label);
}
