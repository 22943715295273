@import '~@packages/ui-kit/styles/mixins';

@mixin text-zone {
	&.content {
		&.heading {
			color: var(--swatch-content-text-headings);
		}

		&.strong {
			color: var(--swatch-content-text-strong);
		}

		&.subtle {
			color: var(--swatch-content-text-subtle);
		}

		&.link {
			color: var(--swatch-content-text-link);
		}
	}

	&.card {
		&.heading {
			color: var(--swatch-card-text-headings);
		}

		&.strong {
			color: var(--swatch-card-text-strong);
		}

		&.subtle {
			color: var(--swatch-card-text-subtle);
		}

		&.link {
			color: var(--swatch-card-text-link);
		}
	}

	&.hero {
		&.heading {
			color: var(--swatch-hero-text-headings);
		}

		&.strong {
			color: var(--swatch-hero-text-strong);
		}

		&.subtle {
			color: var(--swatch-hero-text-subtle);
		}

		&.link {
			color: var(--swatch-hero-text-link);
		}
	}
}

@mixin with-styled-text($prefix, $exclude...) {
	$font-text-properties: color, font-size, font-family, font-weight, font-style, line-height, letter-spacing,
		text-transform, text-align;

	$font-text-properties-map: (
		'color': 'colour'
	);

	@each $prop in $font-text-properties {
		@if index($exclude, $prop) == null {
			$value: map-get($font-text-properties-map, $prop) or $prop;

			#{$prop}: var(--#{$prefix}-#{$value});
		}
	}
}

@mixin with-accounts($property) {
	$accounts: (
		htbISA: --btn-account-htb-isa-color,
		lifetimeISA: --btn-account-lisa-color,
		regularSavings: --btn-account-regular-savings-color,
		cashISA: --btn-account-cash-isa-color,
		stockAndSharesISA: --btn-account-stock-and-shares-isa-color,
		current: --btn-account-current-color,
		others: --btn-account-others-color
	);

	@each $name, $value in $accounts {
		&.#{$name} {
			#{$property}: var(#{$value});
			@content;
		}
	}
}
