@import '../resources/vars';
@import '../resources/mixins';

.container {
	--app-input-container-direction: var(--select-container-direction);
	--app-input-container-align-items: var(--select-container-align-items);
	--app-input-container-justify-content: var(--select-container-justify-content);
	--app-input-container-padding: var(--select-container-padding);
	--app-input-container-border-width: var(--select-container-border-width);
	--app-input-container-border-style: var(--select-container-border-style);
	--app-input-container-border-color: var(--select-container-border-color);

	--app-input-label-direction: var(--select-label-direction);
	--app-input-label-justify-content: var(--select-label-justify-content);
	--app-input-label-align-items: var(--select-label-align-items);
	--app-input-label-margin: var(--select-label-margin);

	--app-input-informer-margin: var(--select-informer-margin);
}

.label {
	@include with-styled-text(select-label, 'font-size');
	font-size: var(--app-select-label-font-size, var(--select-label-font-size));
}

.field {
	background-color: var(--select-field-background-color);
	border-color: var(--select-field-border-color);
	border-radius: var(--select-field-border-radius);
	border-style: var(--select-field-border-style);
	border-width: var(--select-field-border-width);
	box-shadow: var(--select-field-box-shadow);
	height: var(--input-height, var(--select-field-height));
	padding: var(--select-field-padding);
	width: 100%;
}

.field {
	align-items: center;
	display: flex;
}

.value {
	@extend %truncated-text;

	@include with-styled-text(select-value);
	flex: 1;
}

.icon {
	margin: var(--select-icon-margin);

	svg {
		height: var(--select-icon-size);
		width: var(--select-icon-size);
	}

	path {
		fill: var(--select-icon-colour);
	}
}
