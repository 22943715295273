@import '../resources/vars';
@import '../resources/mixins';

.media {
	display: flex;
	justify-content: center;

	@include respond-to(sm, up) {
		justify-content: flex-end;
	}
}

.image {
	max-width: 278px;
}

.powered {
	color: #545454;
	font-size: 14px;
	font-weight: normal;
}
