@import '../resources/vars';
@import '../resources/mixins';

.container {
	position: relative;

	&.as-16x9 {
		padding-bottom: 56.2%;
	}

	&.as-4x3 {
		padding-bottom: 75%;
	}

	&.as-3x2 {
		padding-bottom: 66.66%;
	}

	&.as-8x5 {
		padding-bottom: 62.5%;
	}

	&.as-1x1 {
		padding-bottom: 100%;
	}
}

.content {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
