@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--forms-segmented-btn-bg-colour);
	border: var(--forms-segmented-btn-border);
	border-radius: var(--forms-segmented-btn-border-radius);
	box-shadow: var(--forms-segmented-btn-box-shadow);
	display: flex;
	height: var(--forms-segmented-btn-height);
	padding: var(--forms-segmented-btn-padding);
}

.segment {
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
	min-width: 0;
	padding: var(--forms-segmented-btn-item-padding);

	&:not(:first-child) {
		margin-left: var(--forms-segmented-btn-item-margin);
	}
}

.segment {
	background-color: var(--forms-segmented-btn-item-off-bg-colour);
	border: var(--forms-segmented-btn-item-off-border);
	border-radius: var(--forms-segmented-btn-item-off-inner-radius);
	box-shadow: var(--forms-segmented-btn-item-off-box-shadow);
	color: var(--forms-segmented-btn-item-off-label-colour);

	&:first-child {
		border-bottom-left-radius: var(--forms-segmented-btn-item-off-outer-radius);
		border-top-left-radius: var(--forms-segmented-btn-item-off-outer-radius);
	}

	&:last-child {
		border-bottom-right-radius: var(--forms-segmented-btn-item-off-outer-radius);
		border-top-right-radius: var(--forms-segmented-btn-item-off-outer-radius);
	}

	&.active {
		background-color: var(--forms-segmented-btn-item-on-bg-colour);
		border: var(--forms-segmented-btn-item-on-border);
		border-radius: var(--forms-segmented-btn-item-on-inner-radius);
		box-shadow: var(--forms-segmented-btn-item-on-box-shadow);
		color: var(--forms-segmented-btn-item-on-label-colour);

		&:first-child {
			border-bottom-left-radius: var(--forms-segmented-btn-item-on-outer-radius);
			border-top-left-radius: var(--forms-segmented-btn-item-on-outer-radius);
		}

		&:last-child {
			border-bottom-right-radius: var(--forms-segmented-btn-item-on-outer-radius);
			border-top-right-radius: var(--forms-segmented-btn-item-on-outer-radius);
		}
	}
}

.segment {
	font-family: var(--forms-segmented-btn-item-label-face);
	font-size: var(--forms-segmented-btn-item-label-size);
	font-style: var(--forms-segmented-btn-item-off-label-font-style);
	font-weight: var(--forms-segmented-btn-item-off-label-weight);
	letter-spacing: var(--forms-segmented-btn-item-off-label-letter-spacing);
	line-height: var(--forms-segmented-btn-item-off-label-line-height);
	text-transform: var(--forms-segmented-btn-item-off-label-text-transform);

	&.active {
		font-style: var(--forms-segmented-btn-item-on-label-font-style);
		font-weight: var(--forms-segmented-btn-item-on-label-weight);
		letter-spacing: var(--forms-segmented-btn-item-on-label-letter-spacing);
		line-height: var(--forms-segmented-btn-item-on-label-line-height);
		text-transform: var(--forms-segmented-btn-item-on-label-text-transform);
	}
}

.label {
	line-height: 1.2;
	text-align: center;
}
