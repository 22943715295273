@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--modal-header-background-color);
	border: var(--modal-header-keyline-bottom);
	padding: 0 var(--app-custom-grid-gap-column);
}

.wrapper {
	flex: 1;
	padding: var(--modal-header-spacer-top) 0 var(--modal-header-spacer-bottom);
}

.label {
	align-items: center;
	display: flex;
}

.title {
	flex: 1 0;
}

.title {
	@include with-styled-text(modal-header-heading);
}

.subtitle {
	margin-top: var(--modal-header-spacer-below-heading);
}

.subtitle {
	@include with-styled-text(modal-header-sub-heading);
}

.icon {
	background-color: var(--modal-header-icon-bg-color);
	border: var(--modal-header-icon-bg-border);
	border-radius: var(--modal-header-icon-bg-border-radius);
	flex-shrink: 0;
	margin: var(--modal-header-icon-bg-margin);
	padding: var(--modal-header-icon-bg-padding);

	&:only-child {
		margin-left: auto;
	}

	svg {
		height: var(--modal-header-icon-height);
		width: var(--modal-header-icon-width);

		path {
			fill: var(--modal-header-icon-color);
		}
	}
}
