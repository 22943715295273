@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--forms-error-background-color);
	margin: var(--forms-error-margin);
	padding: var(--forms-error-padding);
}

.container {
	align-items: center;
	display: flex;
}

.icon {
	display: var(--forms-error-icon-display);
	margin: var(--forms-error-icon-margin);

	svg {
		height: var(--forms-error-icon-size);
		width: var(--forms-error-icon-size);
	}

	path {
		fill: var(--forms-error-icon-colour);
	}
}

.message {
	@include with-styled-text(forms-error-label);
}
