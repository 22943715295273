@import '../resources/vars';
@import '../resources/mixins';

.provider {
	--input-spacer: var(--input-group-spacer);
	--input-align: var(--input-group-align);
	--input-width: 100%;

	&.coach {
		--input-spacer: var(--coach-spacer-input);
	}

	&.stack {
		--input-spacer: 0;
	}

	&.question {
		--input-width: var(--btn-max-width);
		--input-height: var(--btn-companion-height);
	}
}
