@import '../resources/vars';
@import '../resources/mixins';

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: var(--input-align);

	&:not(:first-child) {
		margin-top: var(--input-spacer);
	}
}

.controller {
	display: flex;
	flex-direction: column;
	max-width: var(--input-width);
	width: 100%;
}

.wrapper {
	align-items: flex-end;
	display: flex;
}

.input {
	flex: 1;
}

.button {
	margin-top: var(--input-spacer);
	order: 1;
}
