@import '../resources/vars';
@import '../resources/mixins';

.trigger {
	width: 100%;
}

.container {
	transition: max-height var(--checklist-accordion-duration) var(--checklist-accordion-motion-curve);

	// stylelint-disable-next-line
	&.enterActive, &.exitActive {
		overflow: hidden;
	}
}
