@import '../resources/vars';
@import '../resources/mixins';

$light-grey: #f3f3f3;
$grey: #767676;
$blue: #1474fc;
$black: #000;

.container {
	height: 84px;
	left: 0;
	overflow-x: hidden;
	position: relative;
	top: 0;
	width: 100%;
}

.icon {
	background-size: 64px 64px;
	border-radius: 15px;
	height: 64px;
	left: 30px;
	margin-left: 10px;
	position: absolute;
	top: 10px;
	width: 64px;
}

.title {
	font-size: 14px;
	font-weight: 700;
}

.author,
.price {
	font-size: 12px;
}

.info {
	align-items: center;
	color: $black;
	display: flex;
	height: 64px;
	left: 104px;
	margin-left: 10px;
	overflow-y: hidden;
	position: absolute;
	top: 10px;
	width: 60%;
}

.button {
	border-radius: 5px;
	display: block;
	font-size: 18px;
	min-width: 10%;
	padding: 0 10px;
	position: absolute;
	right: 10px;
	text-align: center;
	top: 32px;
	z-index: 1;

	.label {
		text-align: center;
	}
}

.exit {
	cursor: pointer;
	display: block;
	position: absolute;
	text-align: center;
	top: calc(50% - 6px);

	&::before,
	&::after {
		content: ' ';
		position: absolute;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.ios {
	background: $light-grey;
	font-family: var(--font-family), 'Barlow', sans-serif;

	.exit {
		border: 0;
		height: 12px;
		left: 9px;
		margin-left: 5px;
		width: 12px;

		&::before,
		&::after {
			background: $grey;
			height: 12px;
			width: 1px;
		}
	}

	.button {
		background: $light-grey;
		color: $blue;
		text-decoration: none;
	}
}

.android {
	background: #3d3d3d url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7');
	box-shadow: inset 0 4px 0 #88b131;

	.exit {

		background: #1c1e21;
		border-radius: 14px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);
		color: #b1b1b3;
		font-family: var(--font-family), sans-serif;
		font-size: 20px;
		height: 17px;
		left: 6px;
		line-height: 17px;
		margin-right: 7px;
		text-shadow: 0 1px 1px #000;
		width: 17px;

		&::before,
		&::after {

			background: #b1b1b3;
			height: 11px;
			left: 8px;
			top: 3px;

			width: 2px;
		}

		&:active,
		&:hover {
			color: #eee;
		}
	}

	.icon {
		background-color: transparent;
		box-shadow: none;
	}

	.info {
		color: #ccc;
		text-shadow: 0 1px 2px #000;

		.title {
			color: #fff;
			font-weight: bold;
		}
	}

	.button {
		background: none;
		border-radius: 0;
		box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
		color: #d1d1d1;
		font-size: 14px;
		font-weight: bold;
		min-width: 12%;
		padding: 0;
		right: 20px;
		top: 30px;

		&:active,
		&:hover {
			background: none;
		}

		.label {

			background: #42b6c9;
			background: linear-gradient(to bottom, #42b6c9, #39a9bb);
			box-shadow: none;
			display: block;
			line-height: 24px;
			padding: 0 10px;
			text-align: center;
			text-shadow: none;
			text-transform: none;

			&:active,
			&:hover {
				background: #2ac7e1;
			}
		}
	}
}
