@import '../resources/vars';
@import '../resources/mixins';

.container {
	display: flex;
	height: 100%;
	width: 100%;
}

.top {
	.link {
		&::after {
			background-color: var(--theme-nav-top-indicator-off-colour);
			bottom: 0;
			top: auto;
		}

		&:hover {
			&::after {
				background-color: var(--theme-nav-top-indicator-hover-colour);
			}
		}
	}

	.link.active {
		&::after {
			background-color: var(--theme-nav-top-indicator-on-colour);
		}

		.icon {
			color: var(--theme-nav-top-icon-on-colour);
		}

		.label {
			color: var(--theme-nav-top-label-on-colour);
		}
	}

	.icon {
		background-color: var(--theme-nav-top-icon-bg-colour);
		color: var(--theme-nav-top-icon-off-colour);
		top: var(--nav-top-icon-position);

		svg {
			height: var(--nav-top-icon-size);
			width: var(--nav-top-icon-size);
		}
	}

	.label {
		color: var(--theme-nav-top-label-off-colour);
		top: var(--nav-top-label-position);
	}

	.label {
		@include with-styled-text(nav-top-label, 'color');
	}
}

.item {
	flex: 1;
}

.link {
	display: block;
	font-size: var(--nav-bar-text-size);
	font-weight: var(--nav-bar-text-weight);
	height: 100%;
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	width: 100%;

	&::after {
		background-color: var(--theme-nav-bottom-indicator-off-colour);
		content: '';
		display: block;
		height: 4px;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:hover::after {
		background-color: var(--theme-nav-bottom-indicator-hover-colour);
	}
}

.link.active {
	&::after {
		background-color: var(--theme-nav-bottom-indicator-on-colour);
	}

	.icon {
		color: var(--theme-nav-bottom-icon-on-colour);
	}

	.label {
		color: var(--theme-nav-bottom-label-on-colour);
	}
}

.icon,
.label {
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
}

.icon {
	background-color: var(--theme-nav-bottom-icon-bg-colour);
	color: var(--theme-nav-bottom-icon-off-colour);
	top: var(--nav-bottom-icon-position);

	svg {
		color: inherit;
		height: var(--nav-bottom-icon-size);
		width: var(--nav-bottom-icon-size);

		path {
			fill: currentColor;
		}
	}
}

.label {
	color: var(--theme-nav-bottom-label-off-colour);
	top: var(--nav-bottom-label-position);
}

.label {
	@include with-styled-text(nav-bottom-label, 'color');
}
