.fill-height {
	height: 100% !important;
}

.fill-width {
	width: 100% !important;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.margin-bottom-12 {
	margin-bottom: 12px !important;
}

.margin-bottom-16 {
	margin-bottom: 16px !important;
}

.margin-top-16 {
	margin-top: 16px !important;
}

.margin-top-24 {
	margin-top: 24px !important;
}

.margin-top-auto {
	margin-top: auto !important;
}

.margin-left-auto {
	margin-left: auto !important;
}

.margin-top-none {
	margin-top: 0 !important;
}

.position-relative {
	position: relative !important;
}

.black-text {
	font-weight: bold !important;
}

.text-center {
	text-align: center !important;
}

.thrown-away {
	left: -50000000px !important;
	position: absolute !important;
}
