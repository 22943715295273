@import '../resources/vars';
@import '../resources/mixins';

.container {
	height: 60vh;
	margin: 0 auto;
}

.spinner {
	svg {
		height: var(--spinner-icon-size);
		width: var(--spinner-icon-size);
	}

	path {
		fill: var(--spinner);
	}
}
