@import '../resources/vars';
@import '../resources/mixins';

.container {
	--app-input-container-direction: var(--input-tick-container-direction);
	--app-input-container-align-items: var(--input-tick-container-align-items);
	--app-input-container-justify-content: var(--input-tick-container-justify-content);
	--app-input-container-padding: var(--input-tick-container-padding);
	--app-input-container-border-width: var(--input-tick-container-border-width);
	--app-input-container-border-style: var(--input-tick-container-border-style);
	--app-input-container-border-color: var(--input-tick-container-border-color);

	--app-input-label-direction: var(--input-tick-label-direction);
	--app-input-label-justify-content: var(--input-tick-label-justify-content);
	--app-input-label-align-items: var(--input-tick-label-align-items);
	--app-input-label-margin: var(--input-tick-label-margin);

	--app-input-informer-margin: var(--input-tick-informer-margin);
}

.field:checked + .icon {
	background-color: var(--swatch-forms-tick-bg-on);
	border: var(--swatch-forms-tick-border-on);

	path {
		fill: var(--swatch-forms-tick-icon-on);
	}
}

.icon {
	background-color: var(--swatch-forms-tick-bg-off);
	border: var(--swatch-forms-tick-border-off);
	border-radius: var(--effect-forms-tick-border-radius);

	svg {
		height: var(--swatch-forms-tick-size);
		width: var(--swatch-forms-tick-size);
	}

	path {
		fill: var(--swatch-forms-tick-icon-off);
	}
}

.icon {
	align-items: center;
	display: flex;
	justify-content: center;
}

.icon {
	flex-shrink: 0;
}

.label {
	@include with-styled-text(input-tick-label);
}
