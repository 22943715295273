@import '../resources/vars';
@import '../resources/mixins';

.header {
	background-color: var(--theme-nav-top-bg-colour);
	background-image: var(--theme-nav-top-bg-image-url);
	background-position: var(--theme-nav-top-bg-image-position);
	background-repeat: var(--theme-nav-top-bg-image-repeat);
	background-size: var(--theme-nav-top-bg-image-size);
}

.content {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
}

.sidebar {
	display: flex;
	flex-direction: column;
}

.sidebar {
	@extend %scroll-bar-reset;
	height: calc(100vh - var(--app-top-nav-height));
	left: 0;
	overflow-y: auto;
	position: fixed;
	width: var(--app-sidebar-width);
}

.footer {
	background-color: var(--theme-nav-bottom-bg-colour);
	overflow: hidden;
}
