@import '../resources/vars';
@import '../resources/mixins';

.container {
	border-top: var(--theme-nav-bottom-keyline);
}

.navigation {
	height: var(--app-bottom-nav-height);

	@include respond-to(sm, up) {
		margin: 0 auto;
		width: var(--app-nav-width);
	}
}
