@import '../resources/vars';
@import '../resources/mixins';

.container {
	--app-input-container-direction: var(--forms-textarea-container-direction);
	--app-input-container-align-items: var(--forms-textarea-container-align-items);
	--app-input-container-justify-content: var(--forms-textarea-container-justify-content);
	--app-input-container-padding: var(--forms-textarea-container-padding);
	--app-input-container-border-width: var(--forms-textarea-container-border-width);
	--app-input-container-border-style: var(--forms-textarea-container-border-style);
	--app-input-container-border-color: var(--forms-textarea-container-border-color);

	--app-input-label-direction: var(--forms-textarea-label-direction);
	--app-input-label-justify-content: var(--forms-textarea-label-justify-content);
	--app-input-label-align-items: var(--forms-textarea-label-align-items);
	--app-input-label-margin: var(--forms-textarea-label-margin);

	--app-input-informer-margin: var(--forms-textarea-informer-margin);
}

.field {
	@include with-styled-text(forms-textarea-value);
	background-color: var(--forms-textarea-background-color);
	border-color: var(--forms-textarea-border-color);
	border-radius: var(--forms-textarea-border-radius);
	border-style: var(--forms-textarea-border-style);
	border-width: var(--forms-textarea-border-width);
	height: var(--forms-textarea-height);
	padding: var(--forms-textarea-padding);
	resize: none;
	width: var(--forms-textarea-width);

	@include outline-control() {
		@extend %input-reset;
	}
}

.label {
	@include with-styled-text(forms-textarea-label);
}
