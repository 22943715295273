@import '../resources/vars';
@import '../resources/mixins';

.container {
	&::before,
	&::after {
		content: '';
		display: block;
	}

	&::before {
		border-top: var(--knowledge-section-keyline-top);

		@include respond-to(xss) {
			margin: var(--knowledge-section-keyline-top-margin);
		}

		@at-root :global(.sidebar) & {
			margin: var(--knowledge-section-keyline-top-margin);
		}
	}

	&::after {
		border-bottom: var(--knowledge-section-keyline-bottom);

		@include respond-to(xss) {
			margin: var(--knowledge-section-keyline-bottom-margin);
		}

		@at-root :global(.sidebar) & {
			margin: var(--knowledge-section-keyline-bottom-margin);
		}
	}

	&:first-child::before {
		border-top: var(--knowledge-section-keyline-top-first);
	}
}

.header {
	display: flex;
	padding-bottom: var(--knowledge-section-spacer-bottom);
	padding-top: var(--knowledge-section-spacer-top);
}

.header {
	@include respond-to(xss) {
		margin: var(--knowledge-section-margin);
	}

	@at-root :global(.sidebar) & {
		margin: var(--knowledge-section-margin);
	}
}

.icon {
	display: var(--knowledge-section-icon-display);
	margin: var(--knowledge-section-icon-margin);

	svg {
		height: var(--knowledge-section-icon-size);
		width: var(--knowledge-section-icon-size);
	}

	path {
		color: var(--knowledge-section-icon-colour);
	}
}

.label {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
}

.heading {
	margin: 0 0 var(--knowledge-section-spacer-middle) 0;
}

.heading {
	@include with-styled-text(knowledge-section-heading, 'font-size');
	font-size: var(--knowledge-section-heading-size);
}

.subheading {
	@include with-styled-text(knowledge-section-sub-heading, 'font-size');
	font-size: var(--knowledge-section-sub-heading-size);
}
