@import '../resources/vars';
@import '../resources/mixins';

.container {
	display: block;

	&.cover {
		object-fit: cover;
	}

	&.fill {
		object-fit: fill;
	}
}

.fluid {
	height: 100%;
	object-fit: contain;
	width: 100%;
}

.blur {
	filter: blur(5px);
}
