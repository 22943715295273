@import '../resources/vars';
@import '../resources/mixins';

.container {
	align-items: flex-end;
	padding-top: 16px;

	@include respond-to(sm, up) {
		align-items: center;
		justify-content: center;
	}
}

.dialog {
	border-radius: var(--effect-overlay-border-radius) var(--effect-overlay-border-radius) 0 0;
	flex-basis: 100%;

	@include respond-to(sm, up) {
		border-radius: var(--effect-overlay-border-radius);
		flex-basis: 50%;
	}
}

.dialog {
	background-color: var(--swatch-content-bg);
	max-height: 100%;
	overflow: hidden;
	transition: max-height ease-in-out 200ms;
}

.dialog {
	display: flex;
	flex-direction: column;
}

.dialog {
	-webkit-font-smoothing: antialiased;
	transform: translatez(0);
}

.body {
	padding: var(--app-custom-grid-gap-column);
}

.loader {
	height: 128px;
	width: 128px;
}

.appear,
.enter {
	transform: translateY(100%);
	will-change: transform;
}

// stylelint-disable-next-line
.appearActive, .enterActive {
	transform: translateY(0);
	transition: transform ease-in-out 200ms;
}
