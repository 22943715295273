@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--theme-page-header-bg-colour);

	@at-root :global(.sidebar) & {
		border-right: var(--sidebar-page-header-keyline);
	}

	&::after {
		border-bottom: var(--theme-page-header-keyline);
		display: block;

		@include respond-to(xss) {
			margin: var(--page-header-keyline-margin);
		}

		@at-root :global(.sidebar) & {
			margin: var(--page-header-keyline-margin);
		}

		@at-root .with-keyline#{&} {
			content: '';
		}
	}
}

.wrapper {
	display: flex;
	padding: var(--page-header-spacer-top) 0 var(--page-header-spacer-bottom);
}

.icon {
	margin: var(--page-header-icon-margin);

	svg {
		height: var(--theme-page-header-icon-size);
		width: var(--theme-page-header-icon-size);
	}

	path {
		fill: var(--theme-page-header-icon-colour);
	}
}

.icon-edit {
	align-items: center;
	background-color: var(--swatch-contnet-icon-bg);
	border-radius: 100%;
	display: flex;
	height: 32px;
	justify-content: center;
	width: 32px;

	path {
		fill: var(--swatch-content-icon-link);
	}
}

.body {
	flex: 1;
}

.heading {
	color: var(--theme-page-header-heading-colour);
	font-family: var(--page-header-heading-face);
	font-size: var(--page-header-heading-size);
	font-weight: var(--page-header-heading-weight);
	letter-spacing: var(--page-header-heading-letter-spacing);
	line-height: var(--page-header-heading-line-height);
	margin: 0 0 var(--page-header-spacer-below-heading) 0;
	text-align: var(--page-header-heading-text-align);
	text-transform: var(--page-header-heading-text-transform);
}

.subheading {
	color: var(--theme-page-header-sub-heading-colour);
	font-family: var(--page-header-sub-heading-face);
	font-size: var(--page-header-sub-heading-size);
	font-weight: var(--page-header-sub-heading-weight);
	letter-spacing: var(--page-header-sub-heading-letter-spacing);
	line-height: var(--page-header-sub-heading-line-height);
	text-align: var(--page-header-sub-heading-text-align);
	text-transform: var(--page-header-sub-heading-text-transform);
}
