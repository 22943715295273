@import '../resources/vars';
@import '../resources/mixins';

.container {
	align-items: var(--app-input-container-align-items, var(--input-container-align-items));
	border-color: var(--app-input-container-border-color, var(--input-container-border-color));
	border-style: var(--app-input-container-border-style, var(--input-container-border-style));
	border-width: var(--app-input-container-border-width, var(--input-container-border-width));
	display: flex;
	flex-direction: var(--app-input-container-direction, var(--input-container-direction));
	justify-content: var(--app-input-container-justify-content, var(--input-container-justify-content));
	padding: var(--app-input-container-padding, var(--input-container-padding));
}

.wrapper {
	align-items: var(--app-input-label-align-items, var(--input-label-align-items));
	display: flex;
	flex: 1;
	flex-direction: var(--app-input-label-direction, var(--input-label-direction));
	justify-content: var(--app-input-label-justify-content, var(--input-label-justify-content));
	margin: var(--app-input-label-margin, var(--input-label-margin));
}

.informer {
	background-color: var(--input-informer-background-color);
	border: var(--input-informer-border);
	border-radius: var(--input-informer-border-radius);
	box-shadow: var(--input-informer-box-shadow);
	margin: var(--app-input-informer-margin, var(--input-informer-margin));
	padding: var(--input-informer-padding);

	svg {
		height: var(--input-informer-icon-size);
		width: var(--input-informer-icon-size);
	}

	path {
		color: var(--input-informer-icon-colour);
	}
}

.no-informer {
	visibility: hidden;
}
