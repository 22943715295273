@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--footer-bg-colour);
}

.homepage {
	background-image: var(--footer-bg-image-url);
	background-position: var(--footer-bg-image-position);
	background-repeat: var(--footer-bg-image-repeat);
	background-size: var(--footer-bg-image-size);
	padding: var(--footer-padding);

	@at-root :global(.sidebar) & {
		border-right: var(--sidebar-page-footer-keyline);
	}
}

.body {
	margin: 0 auto;
	max-width: var(--footer-group-max-width);
	min-width: var(--footer-group-min-width);
}

.icons {
	align-items: var(--footer-social-group-align-items);
	display: flex;
	flex-direction: var(--footer-social-group-direction);
	justify-content: var(--footer-social-group-justify-content);
	margin: var(--footer-social-group-margin);
	padding: var(--footer-social-group-padding);
}

.icon {
	filter: var(--footer-social-icons-colour);

	&:not(:last-child) {
		margin: var(--footer-social-icons-margin);
	}

	svg {
		height: var(--footer-social-icons-size);
		width: var(--footer-social-icons-size);
	}
}

.buttons {
	--app-custom-grid-cols: var(--footer-btn-group-columns);
	--app-custom-grid-gap-row: var(--footer-btn-group-row-gutter);
	--app-custom-grid-gap-column: var(--footer-btn-group-column-gutter);
	
	margin: var(--footer-btn-group-margin);
	padding: var(--footer-btn-group-padding);
}

.button {
	background-color: var(--footer-btn-bg-color);
	border: var(--footer-btn-border);
	border-radius: var(--footer-btn-border-radius);
	height: var(--footer-btn-height);
	line-height: var(--footer-btn-height);
	margin: var(--footer-btn-margin);
	padding: var(--footer-btn-padding);
	text-align: var(--footer-btn-align);
}

.button {
	display: flex;
}

.link {
	flex: 1;
}

.link {
	color: var(--footer-btn-label-color);
	font-family: var(--footer-btn-label-font-face);
	font-size: var(--footer-btn-label-font-size);
	font-weight: var(--footer-btn-label-font-weight);
	padding: var(--footer-btn-label-padding);
	text-decoration: var(--footer-btn-label-text-decoration);
}

.text-container {
	margin: var(--footer-text-container-margin);
	padding: var(--footer-text-container-padding);
}

.text {
	color: var(--footer-text-colour);
	font-family: var(--footer-text-font-face);
	font-size: var(--footer-text-font-size);
	font-style: var(--footer-text-font-style);
	font-weight: var(--footer-text-font-weight);
	letter-spacing: var(--footer-text-letter-spacing);
	line-height: var(--footer-text-line-height);
	margin: var(--footer-text-margin);
	text-align: var(--footer-text-align);

	> p {
		margin: inherit;
	}
}

.header {
	display: var(--footer-header-display);
	margin: var(--footer-header-margin);
	padding: var(--footer-header-padding);
}

.title {
	@include with-styled-text(footer-header);
}

.logo-container {
	align-items: var(--footer-logo-container-align-items);
	display: flex;
	flex-direction: var(--footer-logo-container-direction);
	justify-content: var(--footer-logo-container-justify-content);
	margin: var(--footer-logo-container-margin);
	padding: var(--footer-logo-container-padding);
}

.logo {
	height: var(--footer-logo-height);
	width: var(--footer-logo-width);
}
