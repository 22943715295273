@import '../resources/vars';
@import '../resources/mixins';

.top {
	padding-top: var(--sat);
}

.bottom {
	padding-bottom: var(--sab);
}

.left {
	padding-left: var(--sal);
}

.right {
	padding-right: var(--sar);
}
