@import '../resources/vars';
@import '../resources/mixins';

.container {
	background-color: var(--theme-template-bg-colour);
	flex: 1;

	@at-root :global(.sidebar) & {
		border-right: var(--sidebar-page-template-keyline);
	}
}

.component {
	margin: 0 auto;
	max-width: var(--layout-sub-width);
}

.headline {
	background-color: var(--app-template-heading-container-bg-colour, var(--page-template-heading-container-bg-colour));
	border: var(--app-template-heading-container-border, var(--page-template-heading-container-border));
	border-radius: var(--app-template-heading-container-border-radius,
	var(--page-template-heading-container-border-radius));
	box-shadow: var(--app-template-heading-container-box-shadow, var(--page-template-heading-container-box-shadow));
	padding: var(--app-template-heading-container-padding, var(--page-template-heading-container-padding));
}

.title {
	color: var(--app-template-heading-colour, var(--page-template-heading-colour));
	font-family: var(--app-template-heading-font-face, var(--page-template-heading-font-face));
	font-size: var(--app-template-heading-font-size, var(--page-template-heading-font-size));
	font-style: var(--app-template-heading-font-style, var(--page-template-heading-font-style));
	font-weight: var(--app-template-heading-font-weight, var(--page-template-heading-font-weight));
	letter-spacing: var(--app-template-heading-letter-spacing, var(--page-template-heading-letter-spacing));
	line-height: var(--app-template-heading-line-height, var(--page-template-heading-line-height));
	text-align: var(--app-template-heading-text-align, var(--page-template-heading-text-align));
	text-transform: var(--app-template-heading-text-transform, var(--page-template-heading-text-transform));
}

.subtitle {
	color: var(--app-template-par-heading-colour, var(--page-template-par-heading-colour));
	font-family: var(--app-template-par-heading-font-face, var(--page-template-par-heading-font-face));
	font-size: var(--app-template-par-heading-font-size, var(--page-template-par-heading-font-size));
	font-style: var(--app-template-par-heading-font-style, var(--page-template-par-heading-font-style));
	font-weight: var(--app-template-par-heading-font-weight, var(--page-template-par-heading-font-weight));
	letter-spacing: var(--app-template-par-heading-letter-spacing, var(--page-template-par-heading-letter-spacing));
	line-height: var(--app-template-par-heading-line-height, var(--page-template-par-heading-line-height));
	text-align: var(--app-template-par-heading-text-align, var(--page-template-par-heading-text-align));
	text-transform: var(--app-template-par-heading-text-transform, var(--page-template-par-heading-text-transform));
}

.image {
	overflow: hidden;
}

// TODO: Refactor it!!!
/* stylelint-disable */
.body {
	overflow-wrap: break-word;

	:global {
		.space-content {
			@at-root :global(.landing) & {
				margin-bottom: var(--template-landing-spacer-content-s) !important;

				@include respond-to(sm, up) {
					margin-bottom: var(--template-landing-spacer-content-ml) !important;
				}
			}
		}

		.space-component {
			@at-root :global(.landing) & {
				margin-bottom: var(--template-landing-spacer-components-s) !important;

				@include respond-to(sm, up) {
					margin-bottom: var(--template-landing-spacer-content-ml) !important;
				}
			}
		}

		.space-section {
			@at-root :global(.landing) & {
				margin-bottom: var(--template-landing-spacer-sections-s) !important;

				@include respond-to(sm, up) {
					margin-bottom: var(--template-landing-spacer-content-ml) !important;
				}
			}
		}

		.wp-block-media-text {
			display: flex;
			flex: 0 1 auto;
			flex-direction: row;
			justify-content: center;
			margin: 0 -8px;

			&__media,
			&__content {
				padding: 0 8px;

				@include respond-to(sm, up) {
					flex: 1;
				}
			}

			&__media {
				img {
					height: 100%;
					object-fit: contain;
					width: 100%;
				}
			}

			&__content {
				@at-root :global(.landing) & {
					> * {
						margin-bottom: var(--template-landing-spacer-content-s);

						@include respond-to(sm, up) {
							margin-bottom: var(--template-landing-spacer-content-ml);
						}
					}
				}

				p {
					padding-bottom: 0;
				}
			}

			&.reverse {
				flex-direction: row-reverse;
			}

			&.promo-large {
				flex-wrap: wrap;
			}
		}

		.promo-medium .wp-block-media-text {
			&__media img {
				width: 128px;
			}
		}

		.promo-small .wp-block-media-text {
			&__media img {
				width: 40px;
			}
		}

		.wp-block-image {
			display: flex;
			justify-content: center;

			&.alignleft {
				justify-content: flex-start;
			}

			&.alignright {
				justify-content: flex-end;
			}

			img {
				height: auto;
				max-width: 100%;
				object-fit: contain;
			}
		}

		.wp-block-table {
			overflow-x: auto;
		}

		.wp-block-embed {
			margin: var(--wp-block-embed-margin);

			iframe {
				height: 100%;
				width: 100%;
			}
		}

		img,
		iframe {
			display: block;
		}
	}
}
/* stylelint-enable */